@import "icon.css";

body {
  margin: 0;

  /*background: url("~/assets/img/Photo_Cover.jpg") no-repeat;*/
  background-size: cover;
  z-index: 1000;
  opacity: 1.0;

  & div#app {
    width: 100vw;
    height: 100vh;

    &>.host {
      width: 100%;
      height: 100%;

      &>.header,
      &>.header>.el-row,
      &>.header>.el-row>.el-col {
        display: flex;
        align-items: center;
      }

      &>.header,
      &>.header>.el-row {
        width: 100%;
      }

      &>.header>.el-row {
        &>.title {
          font-size: 22px;
        }

        &>.left {
          font-size: 30px;
        }

        &>.left,
        &>.right {
          & i[class^=el-icon],
          & i.iconfont {
            cursor: pointer;
          }

          & i.iconfont {
            font-size: 22px;
            font-weight: bold;
          }
        }
      }

      & .page-container {
        width: 100%;
        overflow: auto;
        background-color: #f7f8fA;
        scroll-behavior: smooth;

        & .page {
          & .el-main {
            overflow: visible;
          }

          & .el-row {
            &>.el-col {
              &>.el-input,
              &>.el-select,
              &>.el-date-picker,
              &>.el-date-editor {
                width: 100%;
              }
            }
          }

          &[data-op="add"],
          &[data-op="edit"],
          &[data-op^="add"],
          &[data-op^="edit"],
          &[data-op$="add"],
          &[data-op$="edit"] {
            & .el-main {
              & .el-card {
                padding: 50px;
              }

              & .el-tabs {
                & .el-card {
                  padding: 0;
                }
              }

              & .el-form {
                &>.el-form-item {
                  & .el-input {
                    width: 400px;
                  }

                  & .el-textarea {
                    width: 600px;
                  }

                  & .el-radio-group {
                    line-height: 40px;
                  }

                  & .el-upload-list {
                    width: 400px;
                  }

                  & .tips {
                    margin-left: 1em;
                  }
                }
              }
            }
          }

          & .el-table {
            & .el-table__header,
            & .el-table__body {
              & .el-table__cell {
                text-align: center;

                &.is-right {
                  text-align: right;
                }

                &.is-left {
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }

    & .menu-vertical:not(.el-menu--collapse) {
      width: 200px;
    }
  }
}

