@font-face {
  font-family: "iconfont"; /* Project id 2242961 */
  src: url("~/assets/font/iconfont.woff2") format('woff2'),
       url("~/assets/font/iconfont.woff") format('woff'),
       url("~/assets/font/iconfont.ttf") format('ttf');
}

i.iconfont {
  font-family: "iconfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
}

i.iconfont.iconfont-sign-out:before, i.iconfont.icon-sign-out:before {
  content: "\E605";
}

i.iconfont.iconfont-home:before, i.iconfont.icon-home:before {
  content: "\E634";
}

i.iconfont.icon-settings:before {
  content: "\E632";
}

i.iconfont.icon-docs:before {
  content: "\E633";
}

i.iconfont.icon-analytiscs:before {
  content: "\E635";
}

i.iconfont.icon-member:before {
  content: "\E636";
}

i.iconfont.icon-user-tag:before {
  content: "\E637";
}

i.iconfont.icon-operation:before {
  content: "\E638";
}

i.iconfont.icon-export:before {
  content: "\E602";
}

i.iconfont.icon-expand-menu:before {
  content: "\E60A";
}

i.iconfont.icon-collapse-menu:before {
  content: "\E601";
}

i.iconfont.icon-user:before {
  content: "\E65C";
}

.iconmima12:before {
  content: "\E604";
}


