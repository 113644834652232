@font-face {
  font-family: iconfont;
  src: url("../font/iconfont.e37ef884.woff2") format("woff2"), url("../font/iconfont.936e4874.woff") format("woff"), url("../font/iconfont.6d69f653.ttf") format("ttf");
}

i.iconfont {
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  font-style: normal;
  font-family: iconfont !important;
}

i.iconfont.iconfont-sign-out:before, i.iconfont.icon-sign-out:before {
  content: "";
}

i.iconfont.iconfont-home:before, i.iconfont.icon-home:before {
  content: "";
}

i.iconfont.icon-settings:before {
  content: "";
}

i.iconfont.icon-docs:before {
  content: "";
}

i.iconfont.icon-analytiscs:before {
  content: "";
}

i.iconfont.icon-member:before {
  content: "";
}

i.iconfont.icon-user-tag:before {
  content: "";
}

i.iconfont.icon-operation:before {
  content: "";
}

i.iconfont.icon-export:before {
  content: "";
}

i.iconfont.icon-expand-menu:before {
  content: "";
}

i.iconfont.icon-collapse-menu:before {
  content: "";
}

i.iconfont.icon-user:before {
  content: "";
}

.iconmima12:before {
  content: "";
}

body {
  z-index: 1000;
  opacity: 1;
  background-size: cover;
  margin: 0;
}

body div#app {
  width: 100vw;
  height: 100vh;
}

body div#app > .host {
  width: 100%;
  height: 100%;
}

body div#app > .host > .header, body div#app > .host > .header > .el-row, body div#app > .host > .header > .el-row > .el-col {
  align-items: center;
  display: flex;
}

body div#app > .host > .header, body div#app > .host > .header > .el-row {
  width: 100%;
}

body div#app > .host > .header > .el-row > .title {
  font-size: 22px;
}

body div#app > .host > .header > .el-row > .left {
  font-size: 30px;
}

body div#app > .host > .header > .el-row > .left i[class^="el-icon"], body div#app > .host > .header > .el-row > .right i[class^="el-icon"], body div#app > .host > .header > .el-row > .left i.iconfont, body div#app > .host > .header > .el-row > .right i.iconfont {
  cursor: pointer;
}

body div#app > .host > .header > .el-row > .left i.iconfont, body div#app > .host > .header > .el-row > .right i.iconfont {
  font-size: 22px;
  font-weight: 700;
}

body div#app > .host .page-container {
  width: 100%;
  scroll-behavior: smooth;
  background-color: #f7f8fa;
  overflow: auto;
}

body div#app > .host .page-container .page .el-main {
  overflow: visible;
}

body div#app > .host .page-container .page .el-row > .el-col > .el-input, body div#app > .host .page-container .page .el-row > .el-col > .el-select, body div#app > .host .page-container .page .el-row > .el-col > .el-date-picker, body div#app > .host .page-container .page .el-row > .el-col > .el-date-editor {
  width: 100%;
}

body div#app > .host .page-container .page[data-op="add"] .el-main .el-card, body div#app > .host .page-container .page[data-op="edit"] .el-main .el-card, body div#app > .host .page-container .page[data-op^="add"] .el-main .el-card, body div#app > .host .page-container .page[data-op^="edit"] .el-main .el-card, body div#app > .host .page-container .page[data-op$="add"] .el-main .el-card, body div#app > .host .page-container .page[data-op$="edit"] .el-main .el-card {
  padding: 50px;
}

body div#app > .host .page-container .page[data-op="add"] .el-main .el-tabs .el-card, body div#app > .host .page-container .page[data-op="edit"] .el-main .el-tabs .el-card, body div#app > .host .page-container .page[data-op^="add"] .el-main .el-tabs .el-card, body div#app > .host .page-container .page[data-op^="edit"] .el-main .el-tabs .el-card, body div#app > .host .page-container .page[data-op$="add"] .el-main .el-tabs .el-card, body div#app > .host .page-container .page[data-op$="edit"] .el-main .el-tabs .el-card {
  padding: 0;
}

body div#app > .host .page-container .page[data-op="add"] .el-main .el-form > .el-form-item .el-input, body div#app > .host .page-container .page[data-op="edit"] .el-main .el-form > .el-form-item .el-input, body div#app > .host .page-container .page[data-op^="add"] .el-main .el-form > .el-form-item .el-input, body div#app > .host .page-container .page[data-op^="edit"] .el-main .el-form > .el-form-item .el-input, body div#app > .host .page-container .page[data-op$="add"] .el-main .el-form > .el-form-item .el-input, body div#app > .host .page-container .page[data-op$="edit"] .el-main .el-form > .el-form-item .el-input {
  width: 400px;
}

body div#app > .host .page-container .page[data-op="add"] .el-main .el-form > .el-form-item .el-textarea, body div#app > .host .page-container .page[data-op="edit"] .el-main .el-form > .el-form-item .el-textarea, body div#app > .host .page-container .page[data-op^="add"] .el-main .el-form > .el-form-item .el-textarea, body div#app > .host .page-container .page[data-op^="edit"] .el-main .el-form > .el-form-item .el-textarea, body div#app > .host .page-container .page[data-op$="add"] .el-main .el-form > .el-form-item .el-textarea, body div#app > .host .page-container .page[data-op$="edit"] .el-main .el-form > .el-form-item .el-textarea {
  width: 600px;
}

body div#app > .host .page-container .page[data-op="add"] .el-main .el-form > .el-form-item .el-radio-group, body div#app > .host .page-container .page[data-op="edit"] .el-main .el-form > .el-form-item .el-radio-group, body div#app > .host .page-container .page[data-op^="add"] .el-main .el-form > .el-form-item .el-radio-group, body div#app > .host .page-container .page[data-op^="edit"] .el-main .el-form > .el-form-item .el-radio-group, body div#app > .host .page-container .page[data-op$="add"] .el-main .el-form > .el-form-item .el-radio-group, body div#app > .host .page-container .page[data-op$="edit"] .el-main .el-form > .el-form-item .el-radio-group {
  line-height: 40px;
}

body div#app > .host .page-container .page[data-op="add"] .el-main .el-form > .el-form-item .el-upload-list, body div#app > .host .page-container .page[data-op="edit"] .el-main .el-form > .el-form-item .el-upload-list, body div#app > .host .page-container .page[data-op^="add"] .el-main .el-form > .el-form-item .el-upload-list, body div#app > .host .page-container .page[data-op^="edit"] .el-main .el-form > .el-form-item .el-upload-list, body div#app > .host .page-container .page[data-op$="add"] .el-main .el-form > .el-form-item .el-upload-list, body div#app > .host .page-container .page[data-op$="edit"] .el-main .el-form > .el-form-item .el-upload-list {
  width: 400px;
}

body div#app > .host .page-container .page[data-op="add"] .el-main .el-form > .el-form-item .tips, body div#app > .host .page-container .page[data-op="edit"] .el-main .el-form > .el-form-item .tips, body div#app > .host .page-container .page[data-op^="add"] .el-main .el-form > .el-form-item .tips, body div#app > .host .page-container .page[data-op^="edit"] .el-main .el-form > .el-form-item .tips, body div#app > .host .page-container .page[data-op$="add"] .el-main .el-form > .el-form-item .tips, body div#app > .host .page-container .page[data-op$="edit"] .el-main .el-form > .el-form-item .tips {
  margin-left: 1em;
}

body div#app > .host .page-container .page .el-table .el-table__header .el-table__cell, body div#app > .host .page-container .page .el-table .el-table__body .el-table__cell {
  text-align: center;
}

body div#app > .host .page-container .page .el-table .el-table__header .el-table__cell.is-right, body div#app > .host .page-container .page .el-table .el-table__body .el-table__cell.is-right {
  text-align: right;
}

body div#app > .host .page-container .page .el-table .el-table__header .el-table__cell.is-left, body div#app > .host .page-container .page .el-table .el-table__body .el-table__cell.is-left {
  text-align: left;
}

body div#app .menu-vertical:not(.el-menu--collapse) {
  width: 200px;
}

/*# sourceMappingURL=app.4f83673f.css.map */
